import React from 'react'
import './style.less';
import { Row, Col } from 'antd';
import Link from "../Link";
import Img from "gatsby-image";

class Banner extends React.Component {

    render(){


        return (
            <div id={this.props.id} className="banner" data-bg-color={this.props.bgColor}>
                <Row type="flex" className="content" gutter={70}>
                    <Col xs={{span: 24}} md={{span: 8, push: 16}} lg={{span: 12, push: 12}}>
                        <Img fluid={this.props.image.localFile?.childImageSharp?.fluid} />
                    </Col>
                    <Col xs={{span: 24}} md={{span: 16, pull: 8}} lg={{span: 12, pull: 12}}>
                        {this.props.header !== '' &&
                            <h2 data-color={this.props.headerColor}>{this.props.header}</h2>
                        }
                        {this.props.para.length !== '' &&
                            <div className="content-wrap" data-color={this.props.paraColor}>
                                <p>{this.props.para}</p>
                            </div>
                        }
                        <Link className={"btn btn-" + this.props.btnColor} to={this.props.btnLink}>
                            <span>{this.props.btnText}</span>
                            <span className="icon arrow"></span>
                        </Link>
                    </Col>
                </Row>
            </div>
        );
    }
}

export default Banner