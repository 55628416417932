import React from 'react'
import './style.less';
import Link from "../Link";
import Img from "gatsby-image";

class ListingItem extends React.Component {
    render() {

        return (
            <Link className="listing-item" to={this.props.urlPath} data-color={this.props.color}>
                {this.props.image?.localFile?.childImageSharp?.fluid && <Img fluid={this.props.image?.localFile?.childImageSharp?.fluid} />}
                {this.props.date !== '' &&
                    <span className="date">
                        <span className="clock" />
                        {this.props.date}
                    </span>
                }
                <span className="content-wrap">
                    <span className="title" dangerouslySetInnerHTML={{__html: this.props.title}} />
                </span>
            </Link>
        );
    }
}

export default ListingItem
